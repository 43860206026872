import { useTranslation } from "react-i18next";
import Button from "commons/Button/Button";
import { OfferForCommission } from "api/entities/offer";
import { CommissionForDispatcherCompany } from "api/entities/commission";
import { State } from "api/entities/enums";
import styles from "./SecondaryRow.module.css";
import api from "api";
import Spinner from "commons/Spinner/Spinner";
import { GenericTypeObject } from "api/backend";
import { DataProvider, useDataContext } from "commons/DataManagement/DataProvider";
import { ContextError } from "commons/DataManagement";
import Attachments from "commons/Attachments/Attachments";
import { useConfirm } from "commons/hooks/useConfirm";
import { useState } from "react";
import SecondaryRowEditMode from "./SecondaryRowEditMode";
import { Commission } from "./editCommissionService";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

export const SecondaryRow = <T, G, U>(props: {
    gto: GenericTypeObject<T, G, U>;
    data: CommissionForDispatcherCompany;
    expand: boolean;
    reloadOneRow: () => void;
    inactive?: boolean;
}) => {
    const [editMode, setEditMode] = useState(false);
    const [commentEditMode, setCommentEditMode] = useState(false);
    const [comment, setComment] = useState(props.data.comment ?? "");

    const dataContext = useDataContext<T, G, U>();
    const { t } = useTranslation("dispatcherView", { keyPrefix: "commissions.offers" });
    const { handleErrors } = useErrorHandling();
    const navigate = useNavigate();

    const handleDelete = () => {
        handleCancelCommission();
    };
    const handleAbort = () => {
        /* ... */
    };
    const confirmDelete = useConfirm("Anulować zlecenie?\nKliknij Ok, aby potwierdzić", handleDelete, handleAbort);

    const handleCancelCommission = () => {
        try {
            api.editCommission(props.data.id, { commission_state: State.canceled }).then((x) => {
                handleErrors(x.error);
                props.reloadOneRow();
            });
        } catch (e) {
            console.log(e);
        }
    };

    if (editMode) {
        const comm: Commission = {
            addr_primary_id: props.data.addr_primary_id,
            addr_primary_name: props.data.address_primary.name,
            addr_primary_latitude: props.data.address_primary.latitude,
            addr_primary_longitude: props.data.address_primary.longitude,

            addr_secondary_id: props.data.addr_secondary_id ?? "",
            addr_secondary_name: props.data.address_secondary?.name ?? "",
            addr_secondary_latitude: props.data.address_secondary?.latitude ?? 0.0,
            addr_secondary_longitude: props.data.address_secondary?.longitude ?? 0.0,

            dispatcher_id: props.data.dispatcher_id,
            target_date: new Date(props.data.target_date),
            service_id: props.data.service_id,
            service_name: props.data.service.name,
            train_no: props.data.train_no,
            contact_person: props.data.contact_person ?? "Error: No contact person",
            contact_phone: props.data.contact_phone ?? "Error: No phone",
            locomotive: props.data.locomotive ?? "Error: No locomotive",

            commission_id: props.data.id,
        };

        return (
            <SecondaryRowEditMode
                comm={comm}
                normalView={() => {
                    setEditMode(false);
                    props.reloadOneRow();
                }}
                cancelEdit={() => {
                    setEditMode(false);
                }}
            />
        );
    } else {
        if (dataContext.isUndefined)
            return <ContextError consumerName="DispatcherView.Commissions.SecondaryRow" providerName="DataProvider" />;
        return (
            <div className={styles.content_wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        {props.data.commission_state === State.canceled
                            ? `${t("")} - zlecenie zostało anulowane`
                            : t("")}
                    </div>
                    <DataProvider
                        fetchFun={api.fetchOffersForCommission}
                        fetchArgs={[props.data.id]}
                        query={{
                            order_by: "total_price" as keyof OfferForCommission,
                        }}
                    >
                        {(gto, data, fetched) => {
                            const offers = data ?? [];
                            return (
                                <>
                                    <Spinner
                                        isFetched={fetched || props.data.num_offers_filtered === 0}
                                        size={"80px"}
                                        padding={"20px 0"}
                                    >
                                        {props.data.num_offers_filtered === 0 ? (
                                            <div className={styles.item}>{t("empty")}</div>
                                        ) : (
                                            <div className={styles.item}>
                                                <table className={styles.table}>
                                                    <thead className={styles.thead}>
                                                        <tr>
                                                            <th className={styles.th}>{t("data.auditorCo")}</th>
                                                            <th className={styles.th}>{t("data.auditor")}</th>
                                                            <th className={styles.th}>{t("data.priceServices")}</th>
                                                            <th className={styles.th}>{t("data.priceCommute")}</th>
                                                            <th className={styles.th}>{t("data.price")}</th>
                                                            {props.data.commission_state === State.pending && (
                                                                <th className={styles.th}>{t("data.expireDate")}</th>
                                                            )}
                                                            {props.data.commission_state === State.pending && (
                                                                <th className={styles.th}>{t("data.accept")}</th>
                                                            )}
                                                            {props.data.frontend_status_dispatcher ===
                                                                State.selected_offer_edited && (
                                                                <>
                                                                    <th className={styles.th}>{t("data.accept")}</th>
                                                                    <th className={styles.th}>{t("data.reject")}</th>
                                                                </>
                                                            )}
                                                            <th className={styles.th}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className={styles.tbody}>
                                                        {offers.map((offer) => {
                                                            return (
                                                                <OfferTable
                                                                    key={offer.id}
                                                                    offer={offer}
                                                                    commission_id={props.data.id}
                                                                    editable={
                                                                        props.data.selected_offer_id ? false : true
                                                                    }
                                                                    acceptingOffers={
                                                                        props.data.commission_state === State.pending
                                                                    }
                                                                    reloadOneRow={props.reloadOneRow}
                                                                    selected={props.data.selected_offer_id === offer.id}
                                                                    data={props.data}
                                                                />
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </Spinner>
                                </>
                            );
                        }}
                    </DataProvider>
                </div>
                <div>
                    {((props.data.comment && props.data.comment.length > 0) || commentEditMode) && (
                        <div className={styles.content}>
                            <div className={styles.header}>{t("comment")}</div>
                            {commentEditMode ? (
                                <div
                                    style={{ display: "flex", padding: "10px ", justifyContent: "center", gap: "10px" }}
                                >
                                    <div style={{ width: "80%" }}>
                                        <textarea
                                            id="comment"
                                            className={styles.textarea}
                                            onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                                                setComment(event.currentTarget.value);
                                            }}
                                            value={comment}
                                            placeholder={t("commentPlaceholder") ?? "Komentarz do zlecenia..."}
                                            maxLength={500}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                        <Button
                                            click={() => {
                                                api.editCommission(props.data.id, { comment: comment }).then((x) => {
                                                    handleErrors(x.error);
                                                    props.reloadOneRow();
                                                    setCommentEditMode(false);
                                                });
                                            }}
                                            label={t("confirm") ?? "Potwierdź"}
                                            id="success"
                                        />
                                        <Button
                                            click={() => setCommentEditMode(false)}
                                            label={t("cancel") ?? "Anuluj"}
                                            id={"delete"}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.item}>{props.data.comment}</div>
                            )}
                        </div>
                    )}
                    {/* Załączniki zlecenia, mozna dodawac */}
                    <Attachments
                        addFile={(f: File) => {}}
                        mode="c"
                        id={props.data.id}
                        ableToAdd={
                            props.data.commission_state !== State.canceled &&
                            props.data.commission_state !== State.completed
                        }
                    />

                    {props.inactive === false && (
                        <div style={{ display: "flex", gap: "15px", justifyContent: "flex-end" }}>
                            {(props.data.commission_state === State.pending ||
                                props.data.commission_state === State.active) && (
                                <div className={styles.content_options}>
                                    <Button
                                        label={t("editCommission")}
                                        click={() => {
                                            navigate("/dispatcher/new-commission", {
                                                state: {
                                                    data: props.data,
                                                },
                                            });
                                        }}
                                        id={"success"}
                                    />
                                </div>
                            )}

                            <div className={styles.content_options}>
                                <Button
                                    label={t("addToGroup")}
                                    click={() => {
                                        navigate("/dispatcher/new-commission", {
                                            state: {
                                                data: props.data,
                                                addToGroup: true,
                                            },
                                        });
                                    }}
                                    id={"success"}
                                />
                            </div>

                            <div className={styles.content_options}>
                                <Button
                                    label={
                                        commentEditMode
                                            ? t("editCommentCancel")
                                            : props.data.comment && props.data.comment.length > 0
                                              ? t("editComment")
                                              : t("addComment")
                                    }
                                    click={() => {
                                        setCommentEditMode(!commentEditMode);
                                    }}
                                    id={commentEditMode ? "delete" : "success"}
                                />
                            </div>

                            {props.data.commission_state === State.pending && (
                                <div className={styles.content_options}>
                                    <Button label={t("cancelCommission")} click={confirmDelete} id={"delete"} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
};

function OfferTable(props: {
    offer: OfferForCommission;
    commission_id: string;
    editable: boolean;
    data: CommissionForDispatcherCompany;
    reloadOneRow: () => void;
    selected: boolean;
    acceptingOffers: boolean;
}) {
    const { t } = useTranslation("dispatcherView", { keyPrefix: "commissions.handles" });
    const { handleErrors } = useErrorHandling();
    const [expand, setExpand] = useState(false);
    const dateExp = () => {
        const dateNow = new Date();
        if (props.offer.expiration_date !== undefined && props.offer.expiration_date !== null) {
            const datee = new Date(props.offer.expiration_date);
            return datee < dateNow;
        }
        return false;
    };

    const getDateExp = () => {
        if (props.offer.expiration_date !== undefined && props.offer.expiration_date !== null) {
            const dateExp = new Date(props.offer.expiration_date);
            return (
                dateExp.getDate() +
                "-" +
                dateExp.getMonth() +
                "-" +
                dateExp.getFullYear() +
                " " +
                String(dateExp.getHours()).padStart(2, "0") +
                ":" +
                String(dateExp.getMinutes()).padStart(2, "0")
            );
        }
        return "---";
    };

    const handleClick = () => {
        if (props.editable) {
            api.editCommission(props.commission_id, {
                selected_offer_id: props.offer.id,
            }).then((x) => {
                if (handleErrors(x.error).ok) props.reloadOneRow();
            });
        }
    };

    const handleAcceptEditedOffer = () => {
        api.editCommission(props.data.id, { selected_offer_edited: false }).then((x) => {
            if (handleErrors(x.error).ok) props.reloadOneRow();
        });
    };

    const handleRejectEditedOffer = () => {
        api.editCommission(props.data.id, { commission_state: State.pending }).then((x) => {
            if (handleErrors(x.error).ok) props.reloadOneRow();
        });
    };

    const formatPhoneNo = (x: string): string => {
        return x.replace(/(.{3})/g, "$1 ");
    };

    return (
        <>
            <tr
                className={styles.tr_td}
                style={props.selected ? { textDecoration: "underline", cursor: "pointer" } : { cursor: "pointer" }}
                onClick={() => setExpand(!expand)}
            >
                {props.selected ? (
                    <>
                        <td className={`${styles.td} ${styles.td_with_info}`}>
                            {props.offer.auditor_manager.auditor_company.company_name}
                            <span className={styles.tooltiptext}>
                                <b>{t("phoneNo")}</b>
                                <br />
                                {props.offer.auditor_manager.user.phone_prefix +
                                    " " +
                                    formatPhoneNo(props.offer.auditor_manager.user.phone)}
                            </span>
                        </td>
                        <td className={`${styles.td} ${styles.td_with_info}`}>
                            {`${props.offer.auditor.user.surname} ${props.offer.auditor.user.name}`}
                            <span className={styles.tooltiptext_centered}>
                                <b>{t("phoneNo")}</b>
                                <br />
                                {props.offer.auditor.user.phone_prefix +
                                    " " +
                                    formatPhoneNo(props.offer.auditor.user.phone)}
                                <br />
                                <br />
                                <b>{t("auditorAddress")}</b>
                                <br />
                                {props.offer.addr_name}
                            </span>
                        </td>
                    </>
                ) : (
                    <>
                        <td className={`${styles.td}`}>{props.offer.auditor_manager.auditor_company.company_name}</td>
                        <td className={`${styles.td}`}>
                            {`${props.offer.auditor.user.surname} ${props.offer.auditor.user.name}`}
                        </td>
                    </>
                )}

                <td className={styles.td}>
                    {props.offer.service_price.toFixed(2)} {props.offer.contract_currency}
                </td>

                <td className={styles.td}>
                    {props.offer.commute_price.toFixed(2)} {props.offer.contract_currency}
                </td>

                <td className={styles.td}>
                    {(props.offer.service_price + props.offer.commute_price).toFixed(2)} {props.offer.contract_currency}
                </td>

                {(dateExp() || props.data.commission_state === State.pending) && (
                    <td className={styles.td}>{getDateExp()}</td>
                )}

                {props.acceptingOffers && (
                    <td className={styles.td}>
                        {dateExp() ? (
                            <Button
                                type="button"
                                label={t("expired")}
                                click={() => {}}
                                disabled={true}
                                id={"disabled"}
                            />
                        ) : (
                            <Button
                                type="button"
                                label={t("acceptOffer")}
                                click={handleClick}
                                disabled={props.editable ? false : true}
                                id={props.editable ? "" : "disabled"}
                            />
                        )}
                    </td>
                )}

                {props.data.frontend_status_dispatcher === State.selected_offer_edited && (
                    <>
                        <td className={styles.td}>
                            <Button type="button" label={t("accept")} click={handleAcceptEditedOffer} />
                        </td>
                        <td className={styles.td}>
                            <Button type="button" label={t("reject")} click={handleRejectEditedOffer} id={"delete"} />
                        </td>
                    </>
                )}

                <td className={styles.td}>
                    <FontAwesomeIcon className={styles.icon} icon={faPaperclip} />
                </td>
            </tr>
            {expand && (
                <tr>
                    <td colSpan={100}>
                        <Attachments addFile={(f: File) => {}} mode="o" id={props.offer.id} ableToAdd={false} />
                    </td>
                </tr>
            )}
        </>
    );
}
