import backend from "api/backend";

export interface CompanyCreate {
    tax_id: string;
    regon: string;
    krs_no: string;
    company_name: string;
    address_id: string;
    insurance_amount?: number;
    licence_number?: string;
}

export const companyApiService = {
    registerCompany: (company: CompanyCreate) => {
        return backend.post<CompanyCreate>(`/company`, company);
    },
};
