import styles from "styles/UserListing.module.css";
import api from "api";
import { State } from "api/entities/enums";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { useTranslation } from "react-i18next";
import { DataList, DataProvider, DataStatusProvider, TitleProvider } from "commons/DataManagement";
import { CommissionForDispatcherCompany, CommissionGroup } from "api/entities/commission";
import { CommissionFilter } from "api/filters/commission";
import { useRef } from "react";
import DataGrouper from "commons/DataManagement/DataGrouper";
import { GenericTypeObject } from "api/backend";
import format from "date-fns/format";
import CommissionGroupComponent from "./CommissionGroupComponent";
import { SecondaryRow } from "./SecondaryRow";
import CustomCssTransition from "commons/CustomCssTransition";

export const Commissions = () => {
    const { t } = useTranslation("dispatcherView", { keyPrefix: "commissions" });
    const headerTranslation = useTranslation("dispatcherView", {
        keyPrefix: "commissions.data",
    });
    const statusTranslation = useTranslation("dispatcherView", {
        keyPrefix: "commissions.statuses",
    });

    const filterRef = useRef({
        identifier: Number.NaN,
        commission_no: "",
        address_primary_name: "",
        address_secondary_name: "",
        locomotive: "",
        service_name: "",
    } as CommissionFilter);

    return (
        <>
            <TitleProvider title={`${t("title")} | Raily Marketplace`} />
            <div className={styles.content}>
                <div className={styles.content_row}>
                    <h1 className={styles.h1}> {t("title")} </h1>
                </div>
                <DataProvider
                    fetchFun={api.fetchCommissionsForDispatcherCompany}
                    fetchArgs={[sessionStorageHelper.getDispatcherCoId()]}
                    query={{
                        order_by: "identifier" as keyof CommissionForDispatcherCompany,
                        descending: true,
                    }}
                >
                    {(gto) => (
                        <DataStatusProvider
                            gto={gto}
                            statuses={{
                                commission_edited: {
                                    color: "var(--status-edited)",
                                    condition: (e) => e.frontend_status_dispatcher === State.commission_edited,
                                },
                                pending: {
                                    color: "var(--status-pending)",
                                    condition: (e) => e.frontend_status_dispatcher === State.pending,
                                },
                                new: {
                                    color: "var(--status-new)",
                                    condition: (e) => e.frontend_status_dispatcher === State.new,
                                },
                                active: {
                                    color: "var(--status-accepted)",
                                    condition: (e) => e.frontend_status_dispatcher === State.active,
                                },
                                selected_offer_edited: {
                                    color: "black",
                                    condition: (e) => e.frontend_status_dispatcher === State.selected_offer_edited,
                                },
                            }}
                        >
                            {(statuses, active) => (
                                <>
                                    <div className={styles.content_row}>
                                        <div className={styles.row_items}>
                                            <div className={styles.statuses}>
                                                <DataStatusProvider.Label
                                                    gto={gto}
                                                    st={statusTranslation.t}
                                                    statusWrapperElement={<div className={styles.status_wrapper} />}
                                                    statusElement={<div className={styles.status_header} />}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row_items}>
                                            <DataProvider.RefreshButton gto={gto} label={t("refresh")} />
                                        </div>
                                    </div>
                                    <div className={styles.table_wrapper}>
                                        <DataGrouper
                                            gto={
                                                gto as GenericTypeObject<
                                                    CommissionForDispatcherCompany[],
                                                    CommissionFilter,
                                                    CommissionForDispatcherCompany
                                                >
                                            }
                                            group_type={
                                                {
                                                    data: [],
                                                    group_id: "",
                                                } as CommissionGroup<CommissionForDispatcherCompany>
                                            }
                                            group_func={(e) => {
                                                return {
                                                    grp_id: e.group_id ?? e.id,
                                                    ungrouped: e.group_id === undefined || e.group_id === null,
                                                };
                                            }}
                                        >
                                            {(groups) => (
                                                <DataList
                                                    gto={
                                                        gto as GenericTypeObject<
                                                            CommissionForDispatcherCompany[],
                                                            CommissionFilter,
                                                            CommissionForDispatcherCompany
                                                        >
                                                    }
                                                    keyGenerator={(e) => e.id}
                                                    labelGenerator={{
                                                        targetDate: {
                                                            label: (e) => {
                                                                let date: Date = new Date(
                                                                    e.target_date?.toString() as string,
                                                                );
                                                                return format(date, "dd.MM.yyyy - HH:mm");
                                                            },
                                                            column: "target_date",
                                                            filter: {
                                                                key: "target_date",
                                                                type: "date_range",
                                                            },
                                                        },
                                                        selectedOfferCompanyName: {
                                                            label: (e) => e.selected_offer?.auditor_co_name ?? "---",
                                                            column: "auditor_co_name",
                                                        },
                                                        identifier: {
                                                            label: (e) => e.identifier,
                                                            column: "identifier",
                                                            filter: {
                                                                key: "identifier",
                                                                type: "int",
                                                            },
                                                        },
                                                        trainNo: {
                                                            label: (e) => e.train_no,
                                                            column: "train_no",
                                                            filter: {
                                                                key: "commission_no",
                                                                type: "string",
                                                            },
                                                        },
                                                        address: {
                                                            label: (e) => e.address_primary.name,
                                                            column: "address_primary_name",
                                                            filter: {
                                                                key: "address_primary_name",
                                                                type: "string",
                                                            },
                                                        },
                                                        addressSecondary: {
                                                            label: (e) => e.address_secondary?.name ?? "---",
                                                            column: "address_secondary_name",
                                                            filter: {
                                                                key: "address_secondary_name",
                                                                type: "string",
                                                            },
                                                        },
                                                        locomotive: {
                                                            label: (e) => e.locomotive ?? "---",
                                                            column: "locomotive",
                                                            filter: {
                                                                key: "locomotive",
                                                                type: "string",
                                                            },
                                                        },
                                                        service: {
                                                            label: (e) => e.service.name,
                                                            column: "service_name",
                                                            filter: {
                                                                key: "service_name",
                                                                type: "string",
                                                            },
                                                        },
                                                    }}
                                                    defaultOrder={"identifier"}
                                                    descendingDefaultOrder
                                                    tableWrapperElement={<table className={styles.table} />}
                                                    statusMarkerElement={<div />}
                                                    customBody
                                                >
                                                    {(d, e, k, r, dl, lg, ds) => {
                                                        return (
                                                            <>
                                                                <DataList.Header
                                                                    gto={gto}
                                                                    translation={headerTranslation.t}
                                                                    headerElement={
                                                                        <thead className={styles.thead_no_shadow} />
                                                                    }
                                                                    labelElement={
                                                                        <th scope="col" className={styles.th} />
                                                                    }
                                                                    filterElement={<input className={styles.input} />}
                                                                    filterRef={filterRef}
                                                                />
                                                                {groups.map((g) => {
                                                                    return (
                                                                        <CommissionGroupComponent<
                                                                            CommissionForDispatcherCompany[],
                                                                            CommissionFilter,
                                                                            CommissionForDispatcherCompany
                                                                        >
                                                                            key={`group_${g.group.group_id === "" ? g.group.data[0].id : g.group.group_id}`}
                                                                            data={g}
                                                                            t={t}
                                                                        >
                                                                            {(g, d, e, r) => (
                                                                                <CustomCssTransition
                                                                                    in={e}
                                                                                    trMode={true}
                                                                                    colSpan={9}
                                                                                    id={`element_${d.id}_secondary`}
                                                                                >
                                                                                    <SecondaryRow
                                                                                        gto={g}
                                                                                        data={d}
                                                                                        expand={e}
                                                                                        reloadOneRow={r}
                                                                                        inactive={false}
                                                                                    />
                                                                                </CustomCssTransition>
                                                                            )}
                                                                        </CommissionGroupComponent>
                                                                    );
                                                                })}
                                                            </>
                                                        );
                                                    }}
                                                </DataList>
                                            )}
                                        </DataGrouper>
                                    </div>
                                    <div className={styles.row_items}>
                                        <DataProvider.Pagination gto={gto} />
                                    </div>
                                </>
                            )}
                        </DataStatusProvider>
                    )}
                </DataProvider>
            </div>
        </>
    );
};

export default Commissions;
