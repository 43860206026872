import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import api from "api";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { AuditorWithUserAndAddress } from "api/entities/auditor";
import styles from "./UserMenuInput.module.css";

export default function UserMenuInput(props: {
    value1: string;
    value2: string;
    setInputs: (value1: string, value2: string) => void;

    placeholder1: string;
    placeholder2: string;
}) {
    const { t } = useTranslation("dispatcherView", { keyPrefix: "newCommission" });

    const [employees, setEmployees] = useState<AuditorWithUserAndAddress[]>([]);

    const [showList, setShowList] = useState(false);
    const listRef = useRef(null);
    const listRef2 = useRef(null);
    const searchInput = useRef(null);

    const showListFun = () => {
        async function getData() {
            api.fetchAuditorsByAuditorCompany(sessionStorageHelper.getDispatcherCoId()).then((res) => {
                setEmployees(res.data);
                if (!showList) setShowList(true);
            });
        }

        getData();
    };

    const clickItem = (value1: string, value2: string) => {
        props.setInputs(value1, value2);
        setShowList(false);
    };

    return (
        <div className={styles.content_wrapper}>
            <div className={styles.content}>
                <div className={styles.input_with_label_left}>
                    <div className={styles.label}>
                        <label htmlFor="contactPersonName">{t("contactPerson")}</label>
                    </div>
                    <input
                        id="contactPersonName"
                        className={styles.input}
                        placeholder={t("contactPerson") ?? "Contact person"}
                        ref={searchInput}
                        value={props.value1}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                            props.setInputs(event.currentTarget.value, props.value2);
                            setShowList(false);
                        }}
                        spellCheck={false}
                        autoComplete="off"
                    ></input>
                </div>

                <div className={styles.input_with_label_right}>
                    <div className={styles.label}>
                        <label htmlFor="contactPersonPhone">{t("contactPhone")}</label>
                    </div>
                    <input
                        id="contactPersonPhone"
                        className={styles.input}
                        placeholder={t("contactPhone") ?? "Contact phone"}
                        value={props.value2}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                            props.setInputs(props.value1, event.currentTarget.value);
                            setShowList(false);
                        }}
                        spellCheck={false}
                        autoComplete="off"
                        pattern="([+][0-9]*)?[1-9][0-9\s]*"
                        onInvalid={(e) =>
                            e.currentTarget.setCustomValidity(`${t("phoneError")} +48 123 123 123` ?? t("onInvalid"))
                        }
                        onInput={(e) => e.currentTarget.setCustomValidity("")}
                    ></input>
                </div>
            </div>
            <CSSTransition
                in={!showList}
                nodeRef={listRef2}
                timeout={{
                    enter: 400,
                    exit: 400,
                }}
                classNames={{
                    enter: styles.enter2,
                    enterActive: styles.enterActive2,
                    exit: styles.exit2,
                    exitActive: styles.exitActive2,
                }}
                mountOnEnter
                unmountOnExit
                appear
            >
                <div ref={listRef2} className={styles.list_button} onClick={() => showListFun()}>
                    {t("showEmployees")}
                </div>
            </CSSTransition>
            <CSSTransition
                in={showList}
                nodeRef={listRef}
                timeout={{
                    enter: 400,
                    exit: 400,
                }}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                }}
                mountOnEnter
                unmountOnExit
                appear
            >
                <div className={styles.list_wrapper} ref={listRef}>
                    <div className={styles.list}>
                        {employees.map((data) => {
                            return (
                                <div
                                    onClick={() => {
                                        clickItem(`${data.user_name} ${data.user_surname}`, data.user_phone);
                                    }}
                                    className={styles.list_item}
                                    key={data.id}
                                >
                                    {`${data.user_name} ${data.user_surname}`}
                                </div>
                            );
                        })}
                        {employees.length === 0 && (
                            <div className={styles.list_item} onClick={() => setShowList(false)}>
                                {t("noEmployees")}
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}
