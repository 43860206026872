import backend, { QueryParams } from "api/backend";
import {
    DispatcherWithDispatcherCompany,
    DispatcherWithDispatcherCompanyUser,
    DispatcherWithUser,
} from "api/entities/dispatcher";
import { DispatcherBase, DispatcherEdit } from "api/entities/bases/dispatcher";
import { DispatcherFilter, DispatcherWithCompanyFilter } from "api/filters/dispatcher";
import { UserCreateRequest } from "api/entities/bases/user";

/**
 *  ## Object containing functions that fetch API endpoints related to dispatchers
 *  ### **_dispatcherApiService_** functions:
        fetchDispatchers:
            (q?: QueryParams<DispatcherWithUser[], DispatcherWithCompanyFilter>)
                => Promise<backendResponse<DispatcherWithUser[], DispatcherWithCompanyFilter>>
    <br/>
        fetchDispatcher:
            (id: string [UUID])
                => Promise<backendResponse<DispatcherWithDispatcherCompanyUser, undefined>>
    <br/>
        fetchDispatchersWithDispatcherCompany:
            (q?: QueryParams<DispatcherWithDispatcherCompany[], DispatcherWithCompanyFilter>)
                => Promise<backendResponse<DispatcherWithDispatcherCompany[], DispatcherWithCompanyFilter>>
    <br/>
        fetchDispatchersWithDispatcherCompanyAndUser:
            (q?: QueryParams<DispatcherWithDispatcherCompanyUser[], DispatcherWithCompanyFilter>)
                => Promise<backendResponse<DispatcherWithDispatcherCompanyUser[], DispatcherWithCompanyFilter>>
    <br/>
        fetchDispatchersByDispatcherCompany:
            (dispatcher_co_id: string [UUID], q?: QueryParams<DispatcherWithUser[], DispatcherFilter>)
                => Promise<backendResponse<DispatcherWithUser[], DispatcherFilter>>
    <br/>
        registerDispatcher:
            (dispatcher: DispatcherCreate)
                => Promise<backendResponse<DispatcherBase, undefined>>
    <br/>
        registerDispatcherByAuditorManager:
            (user_id: string [UUID], dispatcher_co_id: string [UUID])
                => Promise<backendResponse<DispatcherBase, undefined>>
    <br/>
        registerDispatcherByAuditor:
            (user_id: string [UUID], dispatcher_co_id: string [UUID])
                => Promise<backendResponse<DispatcherBase, undefined>>
    <br/>
        removeDispatcher:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editDispatcher:
            (id: string [UUID], edit: DispatcherEdit)
                => Promise<backendResponse<DispatcherBase, undefined>>
 */
export const dispatcherApiService = {
    fetchDispatchers: (q?: QueryParams<DispatcherWithUser[], DispatcherWithCompanyFilter>) => {
        return backend.get(`/dispatchers`, q);
    },
    fetchDispatcher: (id: string) => {
        return backend.get<DispatcherWithDispatcherCompanyUser>(`/dispatcher/${id}`);
    },
    fetchDispatchersWithDispatcherCompany: (
        q?: QueryParams<DispatcherWithDispatcherCompany[], DispatcherWithCompanyFilter>,
    ) => {
        return backend.get(`/dispatchers/dispatcher-company`, q);
    },
    fetchDispatchersWithDispatcherCompanyAndUser: (
        q?: QueryParams<DispatcherWithDispatcherCompanyUser[], DispatcherWithCompanyFilter>,
    ) => {
        return backend.get(`/dispatchers/user-and-dispatcher-company`, q);
    },
    fetchDispatchersByDispatcherCompany: (
        dispatcher_co_id: string,
        q?: QueryParams<DispatcherWithUser[], DispatcherFilter>,
    ) => {
        return backend.get(`/dispatcher/user/dispatcher-company/${dispatcher_co_id}`, q);
    },
    registerDispatcher: (dispatcher: UserCreateRequest) => {
        return backend.post<DispatcherBase>(`/dispatcher`, dispatcher);
    },
    removeDispatcher: (id: string) => {
        return backend.delete(`/dispatcher/${id}`);
    },
    editDispatcher: (id: string, edit: DispatcherEdit) => {
        return backend.put<DispatcherBase>(`/dispatcher/${id}`, edit);
    },
};
