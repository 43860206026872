import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMapEvent } from "react-leaflet";

export interface AddressForMap {
    name: string;
    x: number;
    y: number;
}

function Map(props: {
    markers: AddressForMap[];
    padding: string;
    title: boolean;
    height?: string;

    empMarker?: AddressForMap; //if at least of emp options are on, send emp marker
    empAddrTo?: boolean;
    empAddrFrom?: boolean;
    setNewAddr?: (lat: number, lng: number) => void;
    newAddr?: boolean;
}) {
    const { t } = useTranslation("common", { keyPrefix: "map" });

    const lines = () => {
        var array: { ad1: AddressForMap; ad2: AddressForMap }[] = [];
        for (var i = 0; i + 1 < props.markers.length; i += 2) {
            array.push({ ad1: props.markers[i], ad2: props.markers[i + 1] });
        }
        return array;
    };

    function SetNewAddrMarker() {
        useMapEvent("click", (e) => {
            if (props.setNewAddr) props.setNewAddr(e.latlng.lat, e.latlng.lng);
        });

        return null;
    }

    // eslint-disable-next-line
    const linesMemo = useMemo(() => lines(), [props.markers]);

    return (
        <div
            style={{
                textAlign: "left",
                padding: props.padding,
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                zIndex: "1",
            }}
        >
            {props.title === true && <div style={{ marginBottom: "3px" }}>{t("title")}</div>}

            <div style={{ flex: "1", border: "1px solid var(--gray-base)", borderRadius: "4px", height: "100%" }}>
                <MapContainer
                    style={{ width: "100%", height: props.height ?? "600px" }}
                    center={[52.04, 19.28]}
                    zoom={6}
                    scrollWheelZoom={true}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {props.markers.map((e) => {
                        return (
                            <Marker key={`${e.x} ${e.y} ${Math.random()}`} position={[e.x, e.y]}>
                                <Popup>
                                    <span style={{ fontSize: "1.2rem" }}>{e.name}</span>
                                </Popup>
                            </Marker>
                        );
                    })}

                    {props.newAddr === true && <SetNewAddrMarker />}

                    {props.empMarker && (
                        <>
                            <Marker position={[props.empMarker?.x, props.empMarker?.y]}>
                                <Popup>
                                    <span style={{ fontSize: "1.2rem" }}>
                                        {t("empAddr")} <br /> {props.empMarker.name}
                                    </span>
                                </Popup>
                            </Marker>

                            {props.empMarker.x !== 0.0 && props.empMarker.y !== 0.0 && (
                                <>
                                    {props.empAddrTo === true && (
                                        <Polyline
                                            positions={[
                                                [props.empMarker.x, props.empMarker.y],
                                                [props.markers[0].x, props.markers[0].y],
                                            ]}
                                            pathOptions={{ color: "#ffcc00" }}
                                        ></Polyline>
                                    )}

                                    {props.empAddrFrom === true &&
                                        (props.markers.length > 1 || props.empAddrTo === false) && (
                                            <Polyline
                                                positions={[
                                                    [props.empMarker.x, props.empMarker.y],
                                                    [
                                                        props.markers[props.markers.length - 1].x,
                                                        props.markers[props.markers.length - 1].y,
                                                    ],
                                                ]}
                                                pathOptions={{ color: "#ffcc00" }}
                                            ></Polyline>
                                        )}
                                </>
                            )}
                        </>
                    )}

                    {linesMemo.map((e) => {
                        return (
                            <Polyline
                                key={`${e.ad1.x} ${e.ad1.y} ${e.ad2.x}`}
                                positions={[
                                    [e.ad1.x, e.ad1.y],
                                    [e.ad2.x, e.ad2.y],
                                ]}
                                pathOptions={{ color: "#3699ff" }}
                            />
                        );
                    })}
                </MapContainer>
            </div>
        </div>
    );
}

export default React.memo(Map);
