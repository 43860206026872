import { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./Registration.module.css";
import { useTranslation } from "react-i18next";
import { useLoginDebounce } from "api";

interface localProps {
    change: (event: React.FormEvent<HTMLInputElement>) => void;

    text: string;
    id: string;
    placeholder?: any;
    value: any;
    pattern?: string; // pattern w regex
    title?: string; // Wiadomość po najechaniu na pole
    errorMsg?: string; // Wiadomosc gdy input sie nie zgadza
    password?: boolean; // Czy pole ma byc polem password
    login?: boolean;
}
export default function SingleInput(props: localProps) {
    const { t } = useTranslation("common", { keyPrefix: "doubleInput" });

    const loginResp = useLoginDebounce(props.value, 200, props.login ?? false);
    const [style, setStyle] = useState<CSSProperties>();
    const origValue = useRef(props.value);

    useEffect(() => {
        setStyle(
            loginResp.available
                ? {
                      border: "2px solid var(--blue-base)",
                      padding: "6px 7px",
                  }
                : {
                      border: "2px solid #df4f5d",
                      padding: "6px 7px",
                  },
        );
    }, [loginResp.available]);

    return (
        <div className={styles.single_input}>
            <label htmlFor={props.id} className={styles.input_label} title={props.text}>
                {props.text}
            </label>
            <div style={{ backgroundColor: "#fff" }}>
                <input
                    id={props.id}
                    className={styles.input}
                    value={props.value}
                    onChange={props.change}
                    type={props.password ? "password" : "text"}
                    placeholder={props.placeholder}
                    autoComplete="off"
                    required
                    title={props.title ?? ""}
                    pattern={props.pattern ?? ".*"}
                    onInvalid={(e) => {
                        if (props.value === "") {
                            e.currentTarget.setCustomValidity(t("onInvalid"));
                        } else {
                            e.currentTarget.setCustomValidity(props.errorMsg ?? t("onInvalid"));
                        }
                    }}
                    onInput={(e) => e.currentTarget.setCustomValidity("")}
                    spellCheck={false}
                    style={props.login !== true ? {} : props.value === origValue.current ? {} : style}
                ></input>
            </div>
        </div>
    );
}
