import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "api";
import Button from "commons/Button/Button";
import { OfferForAuditorManager } from "api/entities/offer";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { State } from "api/entities/enums";
import { CommissionForAuditorCompany } from "api/entities/commission";
import styles from "./ExpandedRow.module.css";
import Attachments from "commons/Attachments/Attachments";
import NewOffer from "./NewOffer";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

export default function Offers(props: {
    data: CommissionForAuditorCompany;
    expand: boolean;
    reloadOneRow: () => void;
}) {
    const { t } = useTranslation("auditorManagerView", { keyPrefix: "commissions.extension" });
    const { handleErrors } = useErrorHandling();

    const [selected, setSelected] = useState(false);
    const [offer, setOffer] = useState<OfferForAuditorManager>();

    const checkSelected = (o: OfferForAuditorManager) => {
        if (props.data.selected_offer_id === o.id) {
            if (!selected) setSelected(true);
            return true;
        } else return false;
    };

    useEffect(() => {
        // add currency field to the interface
        api.fetchOffersForAuditorCoByCommission(sessionStorageHelper.getAuditorCoId(), props.data.id).then((x) => {
            if (handleErrors(x.error).ok) setOffer(x.data);
        });
    }, [props.expand, props.data, handleErrors]);

    const endCommission = () => {
        api.editCommissionStateForOperator(props.data.id, { commission_state: State.completed }).then((x) => {
            if (handleErrors(x.error).ok) {
                props.reloadOneRow();
            }
        });
    };

    const [editMode, setEditMode] = useState(false);

    if (editMode) {
        return <NewOffer {...props} editMode={true} offer={offer} setEditMode={() => setEditMode(false)} />;
    }

    return (
        <div className={styles.content_wrapper}>
            <div className={styles.content_offers}>
                <div className={styles.header}>{t("yourOffers")}</div>

                {props.data.frontend_status_auditor === State.commission_edited && (
                    <div className={styles.header}>{t("commissionEdited")}</div>
                )}

                <div className={styles.item}>
                    <table className={styles.table}>
                        <thead className={styles.thead}>
                            <tr>
                                <th className={styles.th}>{t("data.auditorMngr")}</th>

                                <th className={styles.th}>{t("data.auditor")}</th>

                                <th className={styles.th}>{t("data.priceService")}</th>

                                <th className={styles.th}>{t("data.priceTransport")}</th>

                                <th className={styles.th}>{t("data.price")}</th>

                                <th className={styles.th}>{t("data.expire")}</th>

                                {offer?.accepted_by_auditor === false && (
                                    <th className={styles.th}>{t("data.confirm")}</th>
                                )}

                                {props.data.frontend_status_auditor === State.commission_edited && (
                                    <>
                                        <th className={styles.th}>{t("data.confirm")}</th>
                                        <th className={styles.th}>{t("data.edit")}</th>
                                    </>
                                )}

                                {(props.data.commission_state === State.pending ||
                                    props.data.frontend_status_auditor === State.commission_edited) && (
                                    <th className={styles.th}>{t("data.cancel")}</th>
                                )}
                            </tr>
                        </thead>

                        <tbody className={styles.tbody}>
                            {offer && (
                                <OfferTable
                                    offer={offer}
                                    reloadOneRow={props.reloadOneRow}
                                    selected={checkSelected(offer)}
                                    editable={props.data.commission_state === State.pending}
                                    data={props.data}
                                    setEditMode={() => setEditMode(true)}
                                />
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {props.data.comment && props.data.comment.length > 0 && (
                <div className={styles.content}>
                    <div className={styles.header}>{t("comment")}</div>
                    <div className={styles.item}>{props.data.comment}</div>
                </div>
            )}

            {selected && (
                <>
                    <div className={styles.content_contact_person}>
                        <div className={styles.header}>Osoba kontaktowa</div>
                        <div className={styles.contact_person}>
                            <table style={{ width: "100%", paddingInline: "25%", fontWeight: "300" }}>
                                <thead>
                                    <tr style={{ height: "30px" }}>
                                        <th style={{ fontWeight: "400" }}>{t("contactPerson.person")}</th>
                                        <th style={{ fontWeight: "400" }}>{t("contactPerson.phoneNo")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ height: "30px" }}>
                                        <td>{props.data.contact_person}</td>
                                        <td>{props.data.contact_prefix_phone + " " + props.data.contact_phone}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}

            {/* Załaczniki do zlecenia, nie mozna dodawac tutaj */}
            <Attachments addFile={(f: File) => {}} mode="c" id={props.data.id} ableToAdd={false} />

            {/* Załączniki do oferty, mozna dodawac */}
            {offer && (
                <Attachments
                    addFile={(f: File) => {}}
                    mode="o"
                    id={offer.id}
                    ableToAdd={
                        props.data.frontend_status_auditor !== State.completed &&
                        props.data.frontend_status_auditor !== State.canceled
                    }
                />
            )}

            {selected && (
                <>
                    {props.data.commission_state !== State.completed &&
                        props.data.commission_state !== State.canceled && (
                            <div style={{ display: "flex", justifyContent: "right", paddingRight: "15px" }}>
                                <Button type="button" label={t("endCommission")} click={endCommission} id={"success"} />
                            </div>
                        )}
                </>
            )}
        </div>
    );
}

function OfferTable(props: {
    offer: OfferForAuditorManager;
    data: CommissionForAuditorCompany;
    reloadOneRow: () => void;
    selected: boolean;
    editable: boolean;
    setEditMode: () => void;
}) {
    const { t } = useTranslation("auditorManagerView", { keyPrefix: "commissions.extension" });
    // eslint-disable-next-line
    const [hover, setHover] = useState(false);
    const { handleErrors } = useErrorHandling();
    const expMsg = t("data.expired");

    // Accepted by auditor edit
    const handleConfirmClick = () => {
        if (!props.offer.accepted_by_auditor)
            api.editOffer(props.offer.id, { accepted_by_auditor: true }).then((x) => {
                if (handleErrors(x.error).ok) {
                    props.reloadOneRow();
                }
            });
    };

    const handleCancelClick = () => {
        api.removeOffer(props.offer.id).then((x) => {
            if (handleErrors(x.error).ok) props.reloadOneRow();
        });
    };

    // accept offer after edit
    const handleConfirmEditedOffer = () => {
        api.editOffer(props.offer.id, {}).then((x) => {
            handleErrors(x.error);
        });
        props.reloadOneRow();
    };

    //
    const handleEditEditedOffer = () => {
        props.setEditMode();
    };

    const getExpirationDate = (): string => {
        if (props.offer.expiration_date !== undefined && props.offer.expiration_date !== null) {
            const dateNow = new Date();
            const dateExp = new Date(props.offer.expiration_date);
            if (dateNow > dateExp) {
                return expMsg;
            } else {
                return (
                    dateExp.getDate() +
                    "-" +
                    dateExp.getMonth() +
                    "-" +
                    dateExp.getFullYear() +
                    " " +
                    String(dateExp.getHours()).padStart(2, "0") +
                    ":" +
                    String(dateExp.getMinutes()).padStart(2, "0")
                );
            }
        }
        return "---";
    };

    return (
        <tr
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={styles.tr_td}
            style={props.selected ? { textDecoration: "underline" } : {}}
        >
            <td className={styles.td}>
                {`${props.offer.auditor_manager.user.surname} ${props.offer.auditor_manager.user.name}`}
            </td>

            <td className={`${styles.td} ${styles.td_with_info}`}>
                {`${props.offer.auditor.user.surname} ${props.offer.auditor.user.name}`}
                <span className={styles.tooltiptext}>
                    <b>{t("data.phoneNo") + ":"}</b>
                    <br />
                    {props.offer.auditor.user.phone_prefix + " " + props.offer.auditor.user.phone}
                    <br />
                    <br /> {/* auditor phone */}
                    <b>{t("data.auditorAddress") + ":"}</b>
                    <br />
                    {props.offer.addr_name}
                </span>
            </td>

            <td className={styles.td}>{props.offer.service_price.toFixed(2) + " " + props.offer.contract_currency}</td>

            <td className={styles.td}>{props.offer.commute_price.toFixed(2) + " " + props.offer.contract_currency}</td>

            <td className={styles.td}>
                {(props.offer.service_price + props.offer.commute_price).toFixed(2) +
                    " " +
                    props.offer.contract_currency}
            </td>

            <td className={styles.td}>{getExpirationDate()}</td>

            {props.offer.accepted_by_auditor === false && (
                <td className={styles.td}>
                    <Button
                        type="button"
                        label={t("data.confirm")}
                        click={handleConfirmClick}
                        id={props.offer.accepted_by_auditor ? "disabled" : ""}
                        disabled={props.offer.accepted_by_auditor ? true : false}
                    />
                </td>
            )}

            {props.data.frontend_status_auditor === State.commission_edited && (
                <>
                    <td className={styles.td}>
                        <Button
                            type="button"
                            label={t("data.confirm")}
                            click={handleConfirmEditedOffer}
                            id={"success"}
                        />
                    </td>
                    <td className={styles.td}>
                        <Button type="button" label={t("data.edit")} click={handleEditEditedOffer} id={"success"} />
                    </td>
                </>
            )}

            {(props.editable || props.data.frontend_status_auditor === State.commission_edited) && (
                <td className={styles.td}>
                    <Button type="button" label={t("data.cancel")} click={handleCancelClick} id={"delete"} />
                </td>
            )}
        </tr>
    );
}
