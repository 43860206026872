import api from "api";
import { CompanyCommon } from "api/entities/bases/companyCommon";
import { ContractWithDispatcherCompany, ContractWithPricesServiceAuditorCompany } from "api/entities/contract";
import Button from "commons/Button/Button";
import CustomCssTransition from "commons/CustomCssTransition";
import { TitleProvider } from "commons/DataManagement";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import Spinner from "commons/Spinner/Spinner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/UserListing.module.css";
import SecondaryRow from "./SecondaryRow";

export default function Contracts() {
    const { t } = useTranslation("dispatcherView", { keyPrefix: "contracts" });

    const [mode, setMode] = useState(true); // true - dispatcher, false - operator
    const [loaded, setLoaded] = useState(false);
    const [disContracts, setDisContracts] = useState<ContractWithPricesServiceAuditorCompany[]>([]);
    const [audContracts, setAudContracts] = useState<ContractWithDispatcherCompany[]>([]);

    useEffect(() => {
        getDispatcherContracts();
    }, []);

    const getDispatcherContracts = () => {
        async function getData() {
            api.fetchContractsByDispatcherCoWithPricesAuditorCompany(sessionStorageHelper.getAuditorCoId()).then(
                (res) => {
                    setDisContracts(res.data);
                    setLoaded(true);
                },
            );
        }
        getData();
    };

    const getOperatorContracts = () => {
        async function getData() {
            api.fetchContractsByAuditorCoWithPricesDispatcherCompany(sessionStorageHelper.getDispatcherCoId()).then(
                (res) => {
                    setAudContracts(res.data);
                    setLoaded(true);
                },
            );
        }
        getData();
    };

    const refresh = () => {
        setLoaded(false);
        if (mode) getDispatcherContracts();
        else getOperatorContracts();
    };

    return (
        <>
            <TitleProvider title={`${t("title")} | Raily Marketplace`} />
            <div className={styles.content}>
                <div className={styles.content_row}>
                    <h1 className={styles.h1}>{t("title")}</h1>
                </div>
                <div className={styles.content_row}>
                    <div className={styles.row_items}>
                        <Button
                            id={mode ? "selected" : ""}
                            click={() => {
                                setMode(true);
                                setLoaded(false);
                                getDispatcherContracts();
                            }}
                            label={t("trans")}
                        />
                        {sessionStorageHelper.getAspects().includes("operator") && (
                            <Button
                                id={!mode ? "selected" : ""}
                                click={() => {
                                    setMode(false);
                                    setLoaded(false);
                                    getOperatorContracts();
                                }}
                                label={t("service")}
                            />
                        )}
                    </div>
                    <div className={styles.row_items}>
                        <Button click={refresh} label={t("refresh")} />
                    </div>
                </div>
                <div className={styles.table_wrapper}>
                    <Spinner isFetched={loaded} size="80px">
                        {mode ? (
                            <table className={styles.table}>
                                <thead className={styles.thead}>
                                    <tr className={styles.tr}>
                                        <th className={styles.th} style={{ cursor: "default" }}>
                                            {t("coName")}
                                        </th>
                                        <th className={styles.th} style={{ cursor: "default" }}>
                                            {t("taxNo")}
                                        </th>
                                        <th className={styles.th} style={{ cursor: "default" }}>
                                            {t("regon")}
                                        </th>
                                        <th className={styles.th} style={{ cursor: "default" }}>
                                            {t("krs")}
                                        </th>
                                        <th className={styles.th} style={{ cursor: "default" }}>
                                            {t("currency")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {disContracts.map((x) => {
                                        return (
                                            <Row
                                                key={x.id}
                                                contract_id={x.id}
                                                company={{
                                                    id: x.auditor_co_id,
                                                    tax_id: x.auditor_company_nip,
                                                    regon: x.auditor_company_regon,
                                                    krs_no: x.auditor_company.krs_no,
                                                    company_name: x.auditor_company_name,
                                                    address_id: x.auditor_company.address_id,
                                                    insurance_amount: x.auditor_company.insurance_amount,
                                                    licence_number: x.auditor_company.licence_number,
                                                }}
                                                currency={x.currency}
                                                editMode={false}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <table className={styles.table}>
                                <thead className={styles.thead}>
                                    <tr className={styles.tr}>
                                        <th className={styles.th}>{t("coNameTrans")}</th>
                                        <th className={styles.th}>{t("taxNo")}</th>
                                        <th className={styles.th}>{t("regon")}</th>
                                        <th className={styles.th}>{t("krs")}</th>
                                        <th className={styles.th}>{t("currency")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {audContracts.map((x) => {
                                        return (
                                            <Row
                                                key={x.id}
                                                contract_id={x.id}
                                                company={{
                                                    id: x.dispatcher_co_id,
                                                    tax_id: x.dispatcher_company.tax_id,
                                                    regon: x.dispatcher_company.regon,
                                                    krs_no: x.dispatcher_company.krs_no,
                                                    company_name: x.dispatcher_company.company_name,
                                                    address_id: x.dispatcher_company.address_id,
                                                }}
                                                currency={x.currency}
                                                editMode={true}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </Spinner>
                </div>
            </div>
        </>
    );
}

function Row(props: { company: CompanyCommon; contract_id: string; currency: string; editMode: boolean }) {
    const [expand, setExpand] = useState(false);

    return (
        <>
            <tr className={styles.tr} onClick={() => setExpand(!expand)}>
                {/* Primary row */}
                <PrimaryRow data={props.company} currecy={props.currency} />
            </tr>

            {/* Secondary expandable row */}
            <CustomCssTransition in={expand} trMode={true} colSpan={8}>
                <SecondaryRow
                    contract_id={props.contract_id}
                    expand={expand}
                    currency={props.currency}
                    company={props.company}
                    editMode={props.editMode}
                />
            </CustomCssTransition>
        </>
    );
}

function PrimaryRow(props: { data: CompanyCommon; currecy: string }) {
    return (
        <>
            <td className={styles.td}>{props.data.company_name}</td>
            <td className={styles.td}>{props.data.tax_id}</td>
            <td className={styles.td}>{props.data.krs_no}</td>
            <td className={styles.td}>{props.data.regon}</td>
            <td className={styles.td}>{props.currecy}</td>
        </>
    );
}
