import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/UserListing.module.css";

import api from "api";
import { AuditorWithUserAndAddress } from "api/entities/auditor";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import Button from "commons/Button/Button";
import { useNavigate } from "react-router-dom";
import { DataList, DataProvider, TitleProvider } from "commons/DataManagement";
import { AuditorUserAddressFilter } from "api/filters/auditor";

export default function Auditors() {
    const { t } = useTranslation("auditorManagerView", { keyPrefix: "auditors" });

    const [active, setActive] = useState(true);

    const filterRef = useRef({
        user_surname: "",
        user_name: "",
        user_login: "",
        address_name: "",
    } as AuditorUserAddressFilter);

    return (
        <>
            <TitleProvider title={`${t("title")} | Raily Marketplace`} />
            <div className={styles.content}>
                <div className={styles.content_row}>
                    <h1 className={styles.h1}>{t("title")}</h1>
                </div>
                <DataProvider
                    fetchFun={active ? api.fetchAuditorsByAuditorCompany : api.fetchAuditorsInactiveByAuditorCompany}
                    fetchArgs={[sessionStorageHelper.getAuditorCoId()]}
                    query={{
                        order_by: "user_surname" as keyof AuditorWithUserAndAddress,
                    }}
                >
                    {(gto, data, fetched, refresh) => (
                        <>
                            <div className={styles.content_row}>
                                <div className={styles.row_items}>
                                    <Button
                                        label={t("active")}
                                        className={active ? styles.button_active : styles.button_inactive}
                                        click={() => {
                                            if (!active) {
                                                setActive(true);
                                                refresh();
                                            }
                                        }}
                                    />
                                    <Button
                                        label={t("inactive")}
                                        className={!active ? styles.button_active : styles.button_inactive}
                                        click={() => {
                                            if (active) {
                                                setActive(false);
                                                refresh();
                                            }
                                        }}
                                    />
                                </div>
                                <div className={styles.row_items}>
                                    <DataProvider.RefreshButton gto={gto} label={t("refresh")} />
                                </div>
                            </div>
                            <div className={styles.table_wrapper}>
                                <DataList
                                    gto={gto}
                                    keyGenerator={(e) => e.id}
                                    labelGenerator={{
                                        surname: {
                                            label: (e) => "",
                                            column: "user_surname",
                                            filter: {
                                                key: "user_surname",
                                                type: "string",
                                            },
                                        },
                                        name: {
                                            label: (e) => "",
                                            column: "user_name",
                                            filter: {
                                                key: "user_name",
                                                type: "string",
                                            },
                                        },
                                        email: {
                                            label: (e) => "",
                                            column: "user_email",
                                        },
                                        phone: {
                                            label: (e) => "",
                                            filter: {
                                                key: "user_phone",
                                                type: "string",
                                            },
                                        },
                                        login: {
                                            label: (e) => "",
                                            column: "user_login",
                                            filter: {
                                                key: "user_login",
                                                type: "string",
                                            },
                                        },
                                        workerTypes: {
                                            label: (e) => "",
                                            column: "types",
                                        },
                                        address: {
                                            label: (e) => "",
                                            column: "address_name",
                                            filter: {
                                                key: "address_name",
                                                type: "string",
                                            },
                                        },
                                    }}
                                    defaultOrder="user_surname"
                                    tableWrapperElement={<table className={styles.table} />}
                                    dataRowElement={<tr className={styles.tr} />}
                                    dataLabelElement={<td className={styles.td} />}
                                    statusMarkerElement={<div className={styles.status_table} />}
                                    customBody
                                >
                                    {() => (
                                        <>
                                            <DataList.Header
                                                gto={gto}
                                                translation={t}
                                                headerElement={<thead className={styles.thead} />}
                                                labelElement={<th scope="col" className={styles.th} />}
                                                filterElement={<input className={styles.input} />}
                                                filterRef={filterRef}
                                            />
                                            <TableContent data={data} active={active} />
                                        </>
                                    )}
                                </DataList>
                            </div>
                            <div className={styles.row_items}>
                                <DataProvider.Pagination gto={gto} />
                            </div>
                        </>
                    )}
                </DataProvider>
            </div>
        </>
    );
}

interface UserTableProps {
    data?: AuditorWithUserAndAddress[];
    active: boolean;
}

export function TableContent({ data, active }: UserTableProps) {
    const { t } = useTranslation("auditorManagerView", { keyPrefix: "auditors" });

    const [editMode, setEditMode] = useState(false);

    return (
        <tbody>
            {data &&
                data.map((x) => {
                    return <Row key={x.id} data={x} editMode={editMode} active={active} />;
                })}
            <tr className={styles.tr}>
                {!editMode && (
                    <td className={styles.td} colSpan={9}>
                        <Button
                            label={t("edit")}
                            click={() => {
                                setEditMode(true);
                            }}
                        />
                    </td>
                )}
                {editMode && (
                    <td className={styles.td} colSpan={10}>
                        <Button
                            id={"delete"}
                            label={t("cancel")}
                            click={() => {
                                setEditMode(false);
                            }}
                        />
                    </td>
                )}
            </tr>
        </tbody>
    );
}

type RowProps = {
    data: AuditorWithUserAndAddress;
    editMode: boolean;
    active: boolean;
};

function Row({ data, editMode, active }: RowProps) {
    const { t } = useTranslation("auditorManagerView", { keyPrefix: "auditors" });
    const et = useTranslation("common", { keyPrefix: "workerTypes" });
    const navigate = useNavigate();

    return (
        <>
            <tr className={styles.tr}>
                <td className={styles.td} data-label={t("surname")}>
                    {data.user_surname}
                </td>
                <td className={styles.td} data-label={t("name")}>
                    {data.user_name}
                </td>
                <td className={styles.td} data-label={t("email")}>
                    {data.user.email}
                </td>
                <td className={styles.td} data-label={t("phone")}>
                    {data.user.phone_prefix + " " + data.user.phone}
                </td>
                <td className={styles.td} data-label={t("login")}>
                    {data.user_login}
                </td>
                <td className={styles.td} data-label={t("workerTypes")}>
                    {data.types.length > 0
                        ? data.types
                              .map((d) => et.t(`${d}`))
                              .sort()
                              .join("; ")
                        : et.t(`NONE`)}
                </td>
                <td className={styles.td} data-label={t("address")}>
                    {data.address.name}
                </td>
                {editMode && (
                    <td className={styles.td}>
                        <Button
                            label={t("edit")}
                            click={() => {
                                if (sessionStorageHelper.getDispatcherId() !== "") {
                                    navigate("/dispatcher/employee-form", {
                                        state: {
                                            employee: data,
                                            edit: true,
                                            inactive: !active,
                                        },
                                    });
                                } else if (sessionStorageHelper.getAuditorManagerId() !== "") {
                                    navigate("/operator/employee-form", {
                                        state: {
                                            employee: data,
                                            edit: true,
                                            inactive: !active,
                                        },
                                    });
                                }
                            }}
                        />
                    </td>
                )}
            </tr>
        </>
    );
}
