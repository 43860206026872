import backend, { QueryParams } from "api/backend";
import {
    AuditorManagerWithAuditorCompany,
    AuditorManagerWithAuditorCompanyUser,
    AuditorManagerWithUser,
} from "api/entities/auditorManager";
import { AuditorManagerBase, AuditorManagerEdit } from "api/entities/bases/auditorManager";
import { UserCreateRequest } from "api/entities/bases/user";
import { AuditorManagerFilter, AuditorManagerWithCompanyFilter } from "api/filters/auditor_manager";

/**
 *  ## Object containing functions that fetch API endpoints related to operators
 *  ### **_auditorManagerApiService_** functions:
        fetchAuditorManagers:
            (q?: QueryParams<AuditorManagerBase[], AuditorManagerWithCompanyFilter>)
                => Promise<backendResponse<AuditorManagerBase[], AuditorManagerWithCompanyFilter>>
    <br/>
        fetchAuditorManager:
            (id: string [UUID])
                => Promise<backendResponse<AuditorManagerBase, undefined>>
    <br/>
        fetchAuditorManagersWithUser:
            (q?: QueryParams<AuditorManagerWithUser[], AuditorManagerWithCompanyFilter>)
                => Promise<backendResponse<AuditorManagerWithUser[], AuditorManagerWithCompanyFilter>>
    <br/>
        fetchAuditorManagerWithUser:
            (id: string [UUID])
                => Promise<backendResponse<AuditorManagerWithUser, undefined>>
    <br/>
        fetchAuditorManagersWithAuditorCompany:
            (q?: QueryParams<AuditorManagerWithAuditorCompany[], AuditorManagerWithCompanyFilter>)
                => Promise<backendResponse<AuditorManagerWithAuditorCompany[], AuditorManagerWithCompanyFilter>>
    <br/>
        fetchAuditorManagerWithAuditorCompany:
            (id: string [UUID])
                => Promise<backendResponse<AuditorManagerWithAuditorCompany, undefined>>
    <br/>
        fetchAuditorManagersWithAuditorCompanyAndUser:
            (q?: QueryParams<AuditorManagerWithAuditorCompanyUser[], AuditorManagerWithCompanyFilter>)
                => Promise<backendResponse<AuditorManagerWithAuditorCompanyUser[], AuditorManagerWithCompanyFilter>>
    <br/>
        fetchAuditorManagerWithAuditorCompanyAndUser:
            (id: string [UUID])
                => Promise<backendResponse<AuditorManagerWithAuditorCompanyUser, undefined>>
    <br/>
        fetchAuditorManagersByAuditorCompany:
            (auditor_co_id: string [UUID], q?: QueryParams<AuditorManagerWithUser[], AuditorManagerFilter>)
                => Promise<backendResponse<AuditorManagerWithUser[], AuditorManagerFilter>>
    <br/>
        registerAuditorManager:
            (manager: AuditorManagerCreate)
                => Promise<backendResponse<AuditorManagerBase, undefined>>
    <br/>
        registerAuditorManagerByDispatcher:
            (user_id: string [UUID], auditor_co_id: string [UUID])
                => Promise<backendResponse<AuditorManagerBase, undefined>>
    <br/>
        registerAuditorManagerByAuditor:
            (user_id: string [UUID], auditor_co_id: string [UUID])
                => Promise<backendResponse<AuditorManagerBase, undefined>>
    <br/>
        removeAuditorManager:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editAuditorManager:
            (id: string [UUID], edit: AuditorManagerEdit)
                => Promise<backendResponse<AuditorManagerBase, undefined>>
 */
export const auditorManagerApiService = {
    fetchAuditorManagers: (q?: QueryParams<AuditorManagerBase[], AuditorManagerWithCompanyFilter>) => {
        return backend.get(`/auditor-managers`, q);
    },
    fetchAuditorManager: (id: string) => {
        return backend.get<AuditorManagerBase>(`/auditor-manager/${id}`);
    },
    fetchAuditorManagersWithUser: (q?: QueryParams<AuditorManagerWithUser[], AuditorManagerWithCompanyFilter>) => {
        return backend.get(`/auditor-managers/user`, q);
    },
    fetchAuditorManagerWithUser: (id: string) => {
        return backend.get<AuditorManagerWithUser>(`/auditor-manager/user/${id}`);
    },
    fetchAuditorManagersWithAuditorCompany: (
        q?: QueryParams<AuditorManagerWithAuditorCompany[], AuditorManagerWithCompanyFilter>,
    ) => {
        return backend.get(`/auditor-managers/auditor-company`, q);
    },
    fetchAuditorManagerWithAuditorCompany: (id: string) => {
        return backend.get<AuditorManagerWithAuditorCompany>(`/auditor-manager/auditor-company/${id}`);
    },
    fetchAuditorManagersWithAuditorCompanyAndUser: (
        q?: QueryParams<AuditorManagerWithAuditorCompanyUser[], AuditorManagerWithCompanyFilter>,
    ) => {
        return backend.get(`/auditor-managers/user-and-auditor-company`, q);
    },
    fetchAuditorManagerWithAuditorCompanyAndUser: (id: string) => {
        return backend.get<AuditorManagerWithAuditorCompanyUser>(`/auditor-manager/user-and-auditor-company/${id}`);
    },
    fetchAuditorManagersByAuditorCompany: (
        auditor_co_id: string,
        q?: QueryParams<AuditorManagerWithUser[], AuditorManagerFilter>,
    ) => {
        return backend.get(`/auditor-manager/user/auditor-company/${auditor_co_id}`, q);
    },
    registerAuditorManager: (manager: UserCreateRequest) => {
        return backend.post<AuditorManagerBase>(`/auditor-manager`, manager);
    },
    removeAuditorManager: (id: string) => {
        return backend.delete(`/auditor-manager/${id}`);
    },
    editAuditorManager: (id: string, edit: AuditorManagerEdit) => {
        return backend.put<AuditorManagerBase>(`/auditor-manager/${id}`, edit);
    },
};
