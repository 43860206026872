import api from "api";
import { GenericTypeObject, QueryParams } from "api/backend";
import { CommissionForDispatcherCompany } from "api/entities/commission";
import { CommissionFilter } from "api/filters/commission";
import Button from "commons/Button/Button";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import FileSaver from "file-saver";
import { MutableRefObject } from "react";

interface CSVButtonProps<T, G, U> {
    gto: GenericTypeObject<T, G, U>;
    filterRef: MutableRefObject<CommissionFilter>;
    query?: QueryParams<T, G, U>;
    label: string;
    className?: string;
}

export const CSVButton = <T, G, U>({ filterRef, query, label, className }: CSVButtonProps<T, G, U>) => {
    const eh = useErrorHandling();
    const fetchCSV = () => {
        let qf = { ...filterRef.current };

        for (let k in qf) {
            let f = k as keyof CommissionFilter;
            if (typeof qf[f] === "string") {
                if (qf[f] === "") {
                    qf[f] = undefined;
                }
            }
            if (typeof qf[f] === "number") {
                if (isNaN(qf[f] as number)) {
                    qf[f] = undefined;
                }
            }
        }

        const q: QueryParams<File, CommissionFilter, CommissionForDispatcherCompany> =
            query !== undefined ? { ...(query as any), filter: qf } : { filter: qf };
        api.fetchFinishedCommissionsCSVForDispatcherCompany(sessionStorageHelper.getDispatcherCoId(), q).then((res) => {
            if (eh.handleErrors(res.error).ok) {
                var date = new Date();
                var fileName: string = `Zlecenia pobrane ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}.csv`;
                FileSaver.saveAs(res.data, fileName);
            }
        });
    };

    return <Button click={fetchCSV} className={className} label={label} />;
};

export default CSVButton;
