import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import ListInput, { ListType } from "commons/Registration/ListInput/ListInput";
import stylesLocal from "./Prices.module.css";
import { PriceWithService } from "api/entities/price";
import { PriceCreate } from "api/entities/bases/price";
import api from "api";
import SingleInput from "commons/Registration/SingleInput";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import DataList from "commons_new/DataList/DataList";

interface ContractInputs extends PriceCreate {
    contract_id: string;
}

enum ButtonMode {
    Default,
    Add,
    Edit,
    Delete,
}

interface LocalProps {
    contract?: string;
}

export default function Prices(props: LocalProps) {
    const { t } = useTranslation("adminView", { keyPrefix: "register.pricing" });
    const { handleErrors } = useErrorHandling();
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(ButtonMode.Default);
    const [editedPrice, setEditedPrice] = useState<{ [name: string]: string }>({});
    const [toRemove, setToRemove] = useState(-1);
    const [contractLocked, setContractLocked] = useState(() => {
        return false;
    });

    const [fetchedData, setFetchedData] = useState<PriceWithService[]>([]);
    const [err, setErr] = useState(false);
    const [key, setKey] = useState(Math.random());

    // Przechowuje info o wybranym kontrakcie
    const [inputs, setInputs] = useState<ContractInputs>({
        contract_id: props.contract ?? "",
        service_id: "",
        price: 0,
    });

    useEffect(() => {
        if (inputs.contract_id !== "") {
            setFetchedData([]);
            if (mode === ButtonMode.Default) {
                api.fetchPricesByContract(inputs.contract_id).then((res) => {
                    if (handleErrors(res.error).ok) setFetchedData(res.data);
                });
            } else if (mode === ButtonMode.Edit) {
                setEditedPrice({});
                api.fetchPricesByContractEditable(inputs.contract_id).then((res) => {
                    if (handleErrors(res.error).ok) setFetchedData(res.data);
                });
            } else if (mode === ButtonMode.Delete) {
                setToRemove(-1);
                api.fetchPricesByContractRemovable(inputs.contract_id).then((res) => {
                    if (handleErrors(res.error).ok) setFetchedData(res.data);
                });
            }
        } else {
            setContractLocked(false);
        }
    }, [mode, inputs.contract_id, handleErrors]);

    const clearInputs = () => {
        setInputs({
            ...inputs,
            service_id: "",
            price: 0,
        });
    };

    const mainRef = useRef(null);

    const [button, setButton] = useState({
        success: false,
    });

    const resetSubmitButton = () => {
        if (button.success) setButton({ success: false });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        resetSubmitButton();
        const value: string = event.currentTarget.value;
        setInputs({
            ...inputs,
            [event.currentTarget.id]: value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setErr(false);
        if (mode === ButtonMode.Add) {
            try {
                if (inputs.service_id === "" || inputs.price <= 0) {
                    setLoading(false);
                    setErr(true);
                    return;
                }
                api.registerPrice({
                    contract_id: inputs.contract_id,
                    service_id: inputs.service_id,
                    price: inputs.price,
                })
                    .then((res) => {
                        clearInputs();
                        setKey(Math.random());
                        if (handleErrors(res.error).ok) {
                            setButton({ success: true });
                        } else {
                            setButton({ success: false });
                        }
                    })
                    .catch((e) => {
                        setButton({ success: false });
                        console.log(e);
                    })
                    .finally(() => {
                        setLoading(false);
                        setMode(ButtonMode.Default);
                    });
            } catch (e) {
                setButton({ success: false });
                console.log(e);
            } finally {
            }
        } else if (mode === ButtonMode.Edit) {
            if (Object.entries(editedPrice).length === 0) {
                setLoading(false);
            }
            try {
                Object.entries(editedPrice).forEach(([name, price]) => {
                    price = parseFloat(price.replace(",", ".")).toFixed(2);
                    if (Number.isNaN(price)) {
                        setLoading(false);
                        return;
                    }

                    const edited = fetchedData.find((v) => v.service.name === name);
                    if (edited && edited.price !== parseFloat(price)) {
                        api.editPrice(edited.contract_id, edited.service_id, { price: parseFloat(price) })
                            .then((res) => {
                                handleErrors(res.error);
                                clearInputs();
                                setButton({ success: true });
                            })
                            .catch((e) => {
                                setButton({ success: false });
                                console.log(e);
                            })
                            .finally(() => {
                                setLoading(false);
                                setMode(ButtonMode.Default);
                            });
                    }
                });
            } catch (e) {
                setButton({ success: false });
                console.log(e);
            } finally {
                setEditedPrice({});
            }
        } else if (mode === ButtonMode.Delete) {
            if (toRemove !== -1) {
                try {
                    api.removePrice(inputs.contract_id, fetchedData[toRemove].service_id)
                        .then((e) => {
                            handleErrors(e.error);
                        })
                        .catch((e) => {
                            setButton({ success: false });
                            console.log(e);
                        })
                        .finally(() => {
                            setLoading(false);
                            setMode(ButtonMode.Default);
                        });
                } catch (e) {
                    setButton({ success: false });
                    console.log(e);
                } finally {
                    setToRemove(-1);
                }
            } else {
                setLoading(false);
            }
        }
    };

    const onChangeEdit = (event: React.FormEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        const valueName = event.currentTarget.id;

        if (Number.isNaN(newValue)) {
            const filtered = Object.entries(editedPrice).filter(([name]) => name !== valueName);

            setEditedPrice({});
            filtered.forEach(([name, price]) => {
                setEditedPrice({
                    ...editedPrice,
                    [name]: price,
                });
            });
        } else
            setEditedPrice({
                ...editedPrice,
                [valueName]: newValue,
            });
    };

    return (
        <form onSubmit={handleSubmit} autoComplete={"off"}>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                {/* Choose from contracts input */}
                <ListInput
                    setInputs={(id: string) => {
                        setInputs({ ...inputs, contract_id: id });
                        setContractLocked(true);
                    }}
                    labelText={t("contract")}
                    id="contract_name"
                    mode={ListType.Contract}
                />

                {/* If contract chosen */}
                <CSSTransition
                    in={contractLocked}
                    nodeRef={mainRef}
                    timeout={{
                        enter: 300,
                        exit: 100,
                    }}
                    classNames={{
                        enter: stylesLocal.pricingsEnter,
                        enterActive: stylesLocal.pricingsEnterActive,
                        exit: stylesLocal.pricingsExit,
                        exitActive: stylesLocal.pricingsExitActive,
                    }}
                    unmountOnExit
                >
                    <div ref={mainRef} className={stylesLocal.contract_pricings}>
                        <div className={stylesLocal.choices}>
                            <button
                                type="button"
                                className={stylesLocal.button}
                                id={mode === ButtonMode.Default ? stylesLocal.button_active : ""}
                                onClick={() => {
                                    setMode(ButtonMode.Default);
                                    resetSubmitButton();
                                }}
                            >
                                {t("services")}
                            </button>
                            <button
                                type="button"
                                className={stylesLocal.button}
                                id={mode === ButtonMode.Add ? stylesLocal.button_active : ""}
                                onClick={() => {
                                    setMode(ButtonMode.Add);
                                    clearInputs();
                                    resetSubmitButton();
                                }}
                            >
                                {t("addService")}
                            </button>
                            <button
                                type="button"
                                className={stylesLocal.button}
                                id={mode === ButtonMode.Edit ? stylesLocal.button_active : ""}
                                onClick={() => {
                                    setMode(ButtonMode.Edit);
                                    resetSubmitButton();
                                }}
                            >
                                {t("editService")}
                            </button>
                            <button
                                type="button"
                                className={stylesLocal.button}
                                id={mode === ButtonMode.Delete ? stylesLocal.button_active : ""}
                                onClick={() => {
                                    setMode(ButtonMode.Delete);
                                    resetSubmitButton();
                                }}
                            >
                                {t("removeService")}
                            </button>
                        </div>
                        {mode === ButtonMode.Default && (
                            <div className={stylesLocal.pricings_list_wrapper}>
                                <div className={stylesLocal.pricings_header}>{t("")}</div>
                                {fetchedData.map((data) => {
                                    return (
                                        <div className={stylesLocal.pricings_item} key={data.service_id}>
                                            <p>
                                                {data.service?.name} - {data.price.toFixed(2)} zł.
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {mode === ButtonMode.Add && (
                            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>

                                <DataList
                                    key={key}
                                    type="service"
                                    setInputs={(id: string) => { setInputs({ ...inputs, service_id: id }); resetSubmitButton(); }}
                                    id="service_name"
                                    labelText={t("serviceName")}
                                    mode="s"
                                    comp_id={inputs.contract_id}

                                />
                                <SingleInput change={handleChange} text={t("price")} id="price" value={inputs.price} />

                                {err && (
                                    <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("valueErr")}</div>
                                )}

                                <SubmitButton loading={loading} success={button.success} />
                            </div>
                        )}
                        {mode === ButtonMode.Edit && (
                            <>
                                <div className={stylesLocal.pricings_list_wrapper}>
                                    <div className={stylesLocal.pricings_header}>Cennik:</div>
                                    <div className={stylesLocal.pricings_items}>
                                        {fetchedData.map((data) => {
                                            const dataname = data.service.name;

                                            return (
                                                <div
                                                    className={stylesLocal.pricings_item}
                                                    key={data.service_id}
                                                    style={{
                                                        display: "flex",
                                                        textAlign: "left",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            display: "inline-block",
                                                            width: "45%",
                                                            padding: "10px",
                                                            height: "36px",
                                                        }}
                                                    >
                                                        {dataname}
                                                    </span>

                                                    <div style={{ width: "40%" }}>
                                                        <input
                                                            id={dataname}
                                                            value={editedPrice[dataname] ?? ""}
                                                            placeholder={`${data.price.toFixed(2)}`}
                                                            type="text"
                                                            autoComplete="off"
                                                            onChange={onChangeEdit}
                                                            style={{
                                                                padding: "5px",
                                                                border: "1px solid var(--gray-base)",
                                                                borderRadius: "4px",
                                                                height: "36px",
                                                                width: "80%",
                                                            }}
                                                            pattern={"[0-9]*[,.]?[0-9]*"}
                                                            onInvalid={(e) =>
                                                                e.currentTarget.setCustomValidity(t("onInvalid"))
                                                            }
                                                            onInput={(e) => e.currentTarget.setCustomValidity("")}
                                                        />

                                                        <span style={{ height: "36px", padding: "10px" }}>zł.</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <SubmitButton loading={loading} success={button.success} />
                            </>
                        )}
                        {mode === ButtonMode.Delete && (
                            <>
                                <div className={stylesLocal.pricings_list_wrapper}>
                                    <div className={stylesLocal.pricings_header}>Cennik:</div>
                                    {fetchedData.map((data, i) => {
                                        return (
                                            <div
                                                className={
                                                    i === toRemove
                                                        ? stylesLocal.pricings_item_marked
                                                        : stylesLocal.pricings_item
                                                }
                                                key={data.service_id}
                                                onClick={() => {
                                                    setToRemove(toRemove === i ? -1 : i);
                                                }}
                                            >
                                                <p>
                                                    {data.service?.name} - {data.price} zł.
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <SubmitButton loading={loading} success={button.success} remove />
                            </>
                        )}
                    </div>
                </CSSTransition>
            </div>
        </form>
    );
}
