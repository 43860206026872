import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DispatcherWithUser } from "api/entities/dispatcher";
import { AuditorWithUserAndAddress } from "api/entities/auditor";
import { ContractWithCompanies } from "api/entities/contract";
import { AuditorCoBase } from "api/entities/bases/auditorCo";
import { DispatcherCoBase } from "api/entities/bases/dispatcherCo";
import { AddressBase } from "api/entities/bases/address";
import { ServiceBase } from "api/entities/bases/service";
import localStyles from "./ListInput.module.css";
import commonStyles from "commons/Registration/Registration.module.css";

import api from "api";
import { WorkerTypeList } from "api/entities/enums";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

export enum ListType {
    AuditorCompany,
    Auditor,
    AuditorByManagerId,
    DispatcherCompany,
    Address,
    Contract,
    Service,
    ServiceAvailableForContract,
    ServiceAvailableInCommission,
    Dispatcher,
    WorkerType,
}

interface localProps {
    setInputs: (id: string, name: string, values?: string[]) => void; // funkcja ustawiająca id i nazwe pola
    labelText: string;
    id: string; // id can be anything, except other html input ids in a form
    mode: ListType; // Jaki typ danych ma wyświetlić lista
    auditor_co_id?: string; // Do pobierania listy rewidentów danej firmy
    contract_id?: string; // do pobierania usług dostępnych do dodania do kontraktu
    resetElement?: boolean;
    updateElement?: boolean;
    onElementClear?: () => void; // dodatkowa funkcja wywoływana przy kliknięciu x, np. do czyszczenia
    onAuditorClick?: (auditorId: string) => void;
    value?: string;
    values?: string[];
    multiple?: boolean;
    resetFormButton?: () => void;
}

export default function ListInput(props: localProps) {
    const { t } = useTranslation("common", { keyPrefix: "listInput" });
    const { handleErrors } = useErrorHandling();
    const et = useTranslation("common", { keyPrefix: "workerTypes" });

    const [isFetched, setIsFetched] = useState(false);
    const fetchedData = useRef<Object[]>([]);

    const [lockedState, setLockedState] = useState(false);

    // Stores value for main input
    const [value, setValue] = useState("");
    const [values, setValues] = useState<string[]>([]);

    //For Csstransition
    const listRef = useRef(null);

    const handleItemClick = (id: string, name: string) => {
        if (props.resetFormButton) props.resetFormButton();
        if (props.multiple === true) {
            const newValues = values.concat(id);
            props.setInputs("", "", newValues);
            setValues(newValues);
            setValue(`${newValues.map((d) => et.t(`${d}`)).join("; ")}`);
        } else {
            props.setInputs(id, name);
            setValue(name);
            setLockedState(true);
        }
        if (props.onAuditorClick) props.onAuditorClick(id);
    };

    const loaded = useRef(false);
    useEffect(() => {
        if (props.multiple === true && props.values) {
            setValues(props.values);
            setValue(props.values.map((d) => et.t(`${d}`)).join("; "));
        } else if (props.value) {
            setValue(props.value);
            setLockedState(true);
        }

        // eslint-disable-next-line
    }, []);

    // Resets component
    useEffect(() => {
        if (loaded.current === false) loaded.current = true;
        else {
            setLockedState(false);
            setValue("");
            setValues([]);
        }
    }, [props.resetElement]);

    useEffect(() => {
        if (loaded.current === true) {
            if (props.multiple === true && props.values) {
                setValues(props.values);
                setValue(props.values.map((d) => et.t(`${d}`)).join("; "));
            } else if (props.value) {
                setValue(props.value);
                setLockedState(true);
            }
        }
        // eslint-disable-next-line
    }, [props.updateElement]);

    useEffect(() => {
        if (!isFetched)
            switch (props.mode) {
                case ListType.AuditorCompany: {
                    api.fetchAuditorCompanies().then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                    });
                    break;
                }
                case ListType.Auditor: {
                    api.fetchAuditorsWithUser().then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                    });
                    break;
                }
                case ListType.AuditorByManagerId: {
                    if (props.auditor_co_id)
                        api.fetchAuditorsByAuditorCompany(props.auditor_co_id).then((res) => {
                            handleErrors(res.error);
                            fetchedData.current = res.data;
                            setIsFetched(true);
                        });
                    break;
                }
                case ListType.DispatcherCompany: {
                    api.fetchDispatcherCompanies().then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                    });
                    break;
                }
                case ListType.Address: {
                    api.fetchAddresses().then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                    });
                    break;
                }
                case ListType.Contract: {
                    api.fetchContractsWithAuditorDispatcherCompany().then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                    });
                    break;
                }
                case ListType.Service: {
                    api.fetchServices().then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                    });
                    break;
                }
                case ListType.ServiceAvailableInCommission: {
                    api.fetchServicesAvailableByCompany(props.auditor_co_id ?? "error").then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                        if (res.data.length === 0) props.setInputs("-1", "-1");
                    });
                    break;
                }
                case ListType.ServiceAvailableForContract: {
                    if (props.contract_id)
                        api.fetchServicesAvailableForContract(props.contract_id).then((res) => {
                            handleErrors(res.error);
                            fetchedData.current = res.data;
                            setIsFetched(true);
                        });
                    break;
                }
                case ListType.Dispatcher: {
                    api.fetchDispatchers().then((res) => {
                        handleErrors(res.error);
                        fetchedData.current = res.data;
                        setIsFetched(true);
                    });
                    break;
                }
                case ListType.WorkerType: {
                    fetchedData.current = WorkerTypeList;
                    setIsFetched(true);
                    break;
                }
            }
    }, [props, props.mode, props.auditor_co_id, props.contract_id, isFetched, value, handleErrors]);

    return (
        <div>
            <div className={commonStyles.single_input}>
                {props.labelText !== "" && (
                    <label htmlFor={props.id} className={commonStyles.input_label} title={props.labelText}>
                        {props.labelText}
                    </label>
                )}
                <div className={localStyles.input_with_button}>
                    {/* Main input */}
                    <input
                        id={props.id}
                        className={commonStyles.input}
                        value={value}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {}}
                        type="text"
                        placeholder={t("chooseFromList") ?? ""}
                        autoComplete="off"
                        readOnly
                        required
                        title=""
                        spellCheck={false}
                        onInvalid={(e) => e.currentTarget.setCustomValidity(t("onInvalid"))}
                        onInput={(e) => {
                            e.currentTarget.setCustomValidity("");
                        }}
                        multiple={props.multiple ?? false}
                    ></input>

                    {/* X Button */}
                    <div
                        onClick={() => {
                            setLockedState(false);
                            setValue("");
                            setValues([]);
                            props.setInputs("", "", []);
                            if (props.onElementClear) props.onElementClear();
                        }}
                        className={localStyles.button}
                        id={lockedState === false && values.length === 0 ? localStyles.button_disabled : ""}
                    >
                        <FontAwesomeIcon
                            className={localStyles.icon}
                            id={lockedState === false && values.length === 0 ? localStyles.icon_disabled : ""}
                            icon={faXmark}
                        />
                    </div>
                </div>
            </div>
            <CSSTransition
                in={lockedState === false}
                nodeRef={listRef}
                timeout={{
                    enter: 400,
                    exit: 400,
                }}
                classNames={{
                    enter: localStyles.enter,
                    enterActive: localStyles.enterActive,
                    exit: localStyles.exit,
                    exitActive: localStyles.exitActive,
                }}
                mountOnEnter
                unmountOnExit
                appear
            >
                <div style={{ padding: "0 10px 0 10px" }} ref={listRef} className={localStyles.list_wrapper}>
                    <div className={localStyles.list}>
                        <MapData
                            mode={props.mode}
                            data={fetchedData.current}
                            currentInput={value}
                            handleClick={handleItemClick}
                            isLocked={lockedState}
                        />
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

interface MapDataProps {
    mode: ListType;
    data: Object[];
    currentInput: string;
    handleClick: (id: string, name: string) => void;
    isLocked: boolean;
}

function MapData(props: MapDataProps) {
    const { t } = useTranslation("common", { keyPrefix: "workerTypes" });

    if (props.mode === ListType.AuditorCompany)
        return (
            <>
                {(props.data as AuditorCoBase[])
                    .filter((data) => data.company_name.toLowerCase().includes(props.currentInput.toLowerCase()))
                    .map((data) => {
                        return (
                            <div
                                onClick={() => props.handleClick(data.id, data.company_name)}
                                className={localStyles.list_item}
                                key={data.id}
                            >
                                {data.company_name}
                            </div>
                        );
                    })}
            </>
        );
    else if (props.mode === ListType.Auditor || props.mode === ListType.AuditorByManagerId)
        return (
            <>
                {(props.data as AuditorWithUserAndAddress[])
                    .filter((data) => data.user.surname.toLowerCase().includes(props.currentInput.toLowerCase()))
                    .map((data) => {
                        return (
                            <div
                                onClick={() => props.handleClick(data.id, `${data.user.surname} ${data.user.name}`)}
                                key={data.id}
                                className={localStyles.list_item}
                                id={props.isLocked ? commonStyles.div_locked : ""}
                            >
                                {`${data.user.surname} ${data.user.name}`}
                            </div>
                        );
                    })}
            </>
        );
    else if (props.mode === ListType.DispatcherCompany)
        return (
            <>
                {(props.data as DispatcherCoBase[])
                    .filter((data) => data.company_name.toLowerCase().includes(props.currentInput.toLowerCase()))
                    .map((data) => {
                        return (
                            <div
                                onClick={() => props.handleClick(data.id, data.company_name)}
                                className={localStyles.list_item}
                                key={data.id}
                            >
                                {data.company_name}
                            </div>
                        );
                    })}
            </>
        );
    else if (props.mode === ListType.Address)
        return (
            <>
                {(props.data as AddressBase[])
                    .filter((data) => data.name.toLowerCase().includes(props.currentInput.toLowerCase()))
                    .map((data) => {
                        return (
                            <div
                                onClick={() => props.handleClick(data.id, data.name)}
                                className={localStyles.list_item}
                                key={data.id}
                            >
                                {data.name}
                            </div>
                        );
                    })}
            </>
        );
    else if (props.mode === ListType.Contract)
        return (
            <>
                {(props.data as ContractWithCompanies[]).map((data) => {
                    let name = `${data.dispatcher_company.company_name} - ${data.auditor_company.company_name}`;
                    return (
                        <div
                            onClick={() => props.handleClick(data.id, name)}
                            className={localStyles.list_item}
                            key={data.id}
                        >
                            {name}
                        </div>
                    );
                })}
            </>
        );
    else if (
        props.mode === ListType.Service ||
        props.mode === ListType.ServiceAvailableForContract ||
        props.mode === ListType.ServiceAvailableInCommission
    )
        return (
            <>
                {(props.data as ServiceBase[])
                    .filter((data) => data.name.toLowerCase().includes(props.currentInput.toLowerCase()))
                    .map((data) => {
                        return (
                            <div
                                onClick={() => props.handleClick(data.id, data.name)}
                                className={localStyles.list_item}
                                key={data.id}
                                id={props.isLocked ? commonStyles.div_locked : ""}
                            >
                                {data.name}
                            </div>
                        );
                    })}
            </>
        );
    else if (props.mode === ListType.Dispatcher)
        return (
            <>
                {(props.data as DispatcherWithUser[])
                    .filter((data) => data.user?.surname.toLowerCase().includes(props.currentInput.toLowerCase()))
                    .map((data) => {
                        let name = data.user!.surname + " " + data.user!.name;
                        return (
                            <div
                                onClick={() => props.handleClick(data.id, name)}
                                className={localStyles.list_item}
                                key={data.id}
                            >
                                {name}
                            </div>
                        );
                    })}
            </>
        );
    else if (props.mode === ListType.WorkerType)
        return (
            <>
                {(props.data as WorkerType[])
                    .filter((data) => !props.currentInput.includes(t(`${data}`)))
                    .map((data) => {
                        return (
                            <div
                                onClick={() => props.handleClick(data, t(`${data}`))}
                                className={localStyles.list_item}
                                key={data}
                            >
                                {t(`${data}`)}
                            </div>
                        );
                    })}
            </>
        );
    else {
        return <></>;
    }
}
