import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DoubleInput from "commons/Registration/DoubleInput";
import { ListType } from "commons/Registration/ListInput/ListInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { Employee, employeeFormService } from "commons/Services/employeeFormService";
import { WorkerType } from "api/entities/enums";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import SingleInput from "commons/Registration/SingleInput";
import CheckboxListInput from "commons/Registration/CheckboxListInput/CheckboxListInput";
import DataList from "commons_new/DataList/DataList";
import ListInputAddrNomi from "commons/Registration/ListInput/ListInputAddrNomi";

export default function Auditor() {
    const { t } = useTranslation("adminView", { keyPrefix: "register.auditor" });
    const { handleErrors } = useErrorHandling();
    const [loading, setLoading] = useState(false);
    const [compErr, setCompErr] = useState(false);
    const [pwdErr, setPwdErr] = useState(false);
    const [key, setKey] = useState(Math.random());

    // Pola do tworzenia nowego rewidenta
    const [inputs, setInputs] = useState(employeeFormService.getDefaultFormData());

    // Czyszczenie pól
    const clearInputs = () => {
        setInputs(employeeFormService.getDefaultFormData());
    };

    const [button, setButton] = useState({
        success: false,
    });

    const resetSubmitButton = () => {
        if (button.success) setButton({ success: false });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        resetSubmitButton();
        if (compErr) setCompErr(false);
        if (pwdErr) setPwdErr(false);
        const value: string = event.currentTarget.value;
        setInputs({
            ...inputs,
            [event.currentTarget.id]: value,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        if (inputs.auditor_co_id === "") {
            setCompErr(true);
            setLoading(false);
            return;
        }

        if (inputs.password !== inputs.password_confirm) {
            setPwdErr(true);
            setLoading(false);
            return;
        }

        const data: Employee = inputs;

        data.phone = data.phone.replace(/\s/g, "");

        if (data.phone.indexOf("+") === -1) {
            data.phone_prefix = "+48";
        } else {
            data.phone_prefix = data.phone.substring(0, 3);
            data.phone = data.phone.substring(3);
        }

        employeeFormService
            .handleSubmit(data)
            .then((s) => {
                if (handleErrors(s).ok) {
                    clearInputs();
                    setLoading(false);
                    setButton({ success: true });
                    setListBool(!listBool);
                    setKey(Math.random());
                    setCompErr(false);
                } else {
                    setLoading(false);
                    setButton({ success: false });
                }
            })
            .catch(() => {
                setLoading(false);
                setButton({ success: false });
            });
    };

    //for refreshing listinputaddr
    const [listBool, setListBool] = useState(false);

    return (
        <form onSubmit={handleSubmit} autoComplete={"off"}>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <DoubleInput
                    change={handleChange}
                    text_i1={t("name")}
                    id_i1="name"
                    value_i1={inputs.name}
                    placeholder_i1={t("name")}
                    pattern_i1="[\p{L}\.\-\,]{2,20}"
                    errorMsg_i1={t("namePattern") ?? "error"}
                    text_i2={t("surname")}
                    id_i2="surname"
                    value_i2={inputs.surname}
                    placeholder_i2={t("surname")}
                    pattern_i2="[\p{L}\.\-\,]{2,20}"
                    errorMsg_i2={t("namePattern") ?? "error"}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("email")}
                    id_i1="email"
                    value_i1={inputs.email}
                    placeholder_i1={t("email")}
                    pattern_i1="[^@]+@[^@]+\.[^@]+"
                    errorMsg_i1={t("emailErr") ?? "error"}
                    text_i2={t("phone")}
                    id_i2="phone"
                    value_i2={inputs.phone}
                    placeholder_i2={t("phone")}
                    pattern_i2="([+][0-9]*)?[1-9][0-9\s]*"
                    errorMsg_i2={`${t("phoneError")} +48 123 123 123`}
                />

                <SingleInput
                    change={handleChange}
                    text={t("login")}
                    id="login"
                    value={inputs.login}
                    placeholder={t("login")}
                    login
                    pattern=".{3,40}"
                    errorMsg={t("loginPatter") ?? "error"}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("password")}
                    id_i1="password"
                    value_i1={inputs.password}
                    placeholder_i1={t("password")}
                    password_i1={true}
                    pattern_i1=".{8,50}"
                    errorMsg_i1={t("passwdPatter") ?? "error"}
                    text_i2={t("password_confirm")}
                    id_i2="password_confirm"
                    value_i2={inputs.password_confirm}
                    placeholder_i2={t("password_confirm")}
                    password_i2={true}
                    pattern_i2=".{8,50}"
                    errorMsg_i2={t("passwdPatter") ?? "error"}
                />

                <CheckboxListInput
                    setInputs={(id, name, values) => {
                        setInputs({ ...inputs, types: values as WorkerType[] });
                    }}
                    labelText={t("workerType")}
                    id="types"
                    mode={ListType.WorkerType}
                    resetElement={listBool}
                />

                <DataList
                    key={key}
                    type="company"
                    setInputs={(id: string) => {
                        setInputs({ ...inputs, auditor_co_id: id });
                        resetSubmitButton();
                    }}
                    id="auditor_co_name"
                    labelText={t("auditorCo")}
                />

                <ListInputAddrNomi
                    setInput={(id: string, name: string, lati: number, long: number) => {
                        setInputs({ ...inputs, addr_id: id, addr_name: name, addr_lati: lati, addr_long: long });
                    }}
                    labelText={t("address")}
                    id={"address"}
                    newAddressEnabled={true}
                    resetElement={listBool}
                />

                {compErr && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("compErr")}</div>}
                {pwdErr && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("repeatPwdErr")}</div>}

                <SubmitButton loading={loading} success={button.success} />
            </div>
        </form>
    );
}
